
/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
    background-color: #2b2e1a00 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(23, 29, 5, 0.171) !important;
    backdrop-filter: blur(15px) !important;
  }
  
  .navbar {
    width: 100%;
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0px !important;
    font-size: 1.2rem !important;
    z-index: 1000;
  }
  
  .navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .nav-link{
    display: flex;
    flex-direction: row;
    color: var(--secondary-color);
  }
  .nav-link {
    color: var(--primary-color) !important;
    cursor: pointer;
    padding: 10px 15px;
    text-decoration: none;
  }
  
  .navbar-toggler span {
    display: block !important;
    background-color: var(--secondary-color) !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    border-radius: 10px;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  
  @media (max-width: 900px) {
    .navbar {
      padding: 0px !important;
      font-size: 1.4rem !important;
      background-color: #373c0000 !important;
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
    .nav-link{
      font-size: 15px;
    }
  }
  .navbar-brand {
    color: var(--primary-color) !important;
  }
  
  .logo {
    color: var(--primary-color) !important;
    font-weight: bolder;
  }
  
  
  
  .navbar-nav .nav-link {
    color: var(--secondary-color) !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
  
    .img-fluid-logo {
      width: 100px !important;
      height: 30px !important;
    }
  
    .logo{
      font-size: 20px;
    }
  
  }
  
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }
  
  .navbar-nav .nav-item::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: var(--secondary-color);
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
    font-weight: bolder;
  }
  
  .navbar-nav .nav-item:hover::after {
    width: 100%;
  }
  
  .logo{
    width: 150px;
    height: 40px;
    object-fit: cover;
  }
  