#home{
    min-height: 100vh;
    
}
.home-section{ 
    position: relative;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
}

.home-section .col-one{
    width: 50%;
    align-items: start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.home-section .col-two{
    width: 20%;
}

.home-section .col-one h1{
    font-size: 3.5rem;
    font-weight: bolder;
    color: var(--secondary-color);
    text-align: left;
}
.home-section .col-one p{
    text-align: left;
    color: var(--text-primary);
    font-size: large
}
.home-section .col-one button{
    align-self: flex-start;
    border-radius: 50px;
    padding: 1rem 2.5rem;
    font-weight: bold;
    background-color: var(--secondary-color);
    border: none;
    color: var(--background);
}

.home-section .col-one .btn:hover{
    background-color: var(--text-primary);
    transition-duration: 300ms;
}

.home-section .col-one .icon{
    margin-left: 35px;
}

.home-section .col-two{
    width: 50%;
}
.home-section .col-two img{
    width: 100%;
    animation: bounceanimation 1800ms  ease-in  0s infinite both alternate;
}

@keyframes bounceanimation {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(10px);
    }
}

@media (max-width: 900px) {
    .container{
        align-items: center;
        justify-content: center;
    }
    .home-section{
        display: flex;
        flex-direction: column-reverse;
        align-self: center;
    }
    .home-section .col-one{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }
    
    .home-section .col-one h1{
        font-size: 2rem;
        text-align: center;
    }
    .home-section .col-one p{
        text-align: center;
        font-size: medium;
    }
    .home-section .col-one button{
        padding: 1rem 2.5rem;
        align-self: center;
    }
    .home-section .col-two{
        width: 100%;
        height: 50%;
        max-height: 450px ;
        margin-left: 10px;
        padding-top: 170px;
    }
    
}



/* --------- */
/* about Section  */
/* --------- */

.about-section{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-top: 10px;
    /* background-color: aqua; */
}
.about-section .col-one{
    width: 40%;
}

.about-section .col-one .image-container{
    width: 100%;
    height: 60vh;
    border: 3px solid var(--secondary-color);
    border-radius: 20px;
}
.about-section .col-one .image-container img{
    width: 100%;
    height: 110%;
    border-radius: 20px;
    object-fit: cover;
    position: relative;
    top: 20px;
    left: 30px;
}
.about-section .col-two{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.about-section .col-two h2{
    color: var(--secondary-color);
    font-weight: 900;
    font-size: 3rem;
}
.about-section .col-two h3{
    color: var(--text-primary);
    padding: 0;
    margin: 0;
}
.about-section .col-two hr{
    height: 2px;
    width: 300px;
    margin-top: 5px;
    color: var(--secondary-color);

}

.about-section .col-two p{
    text-align: left;
    color: var(--text-primary);
}


.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
  }
  
  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: var(--secondary-color);
    border-radius: 50% !important;
    transition: 0.5s !important;
  }
  
  .home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--secondary-color);
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }
  
  .home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px var(--secondary-color);
  }
  
  .home-social-icons:hover {
    color: var(--background);
    box-shadow: 0 0 5px var(--secondary-color);
    text-shadow: 0 0 2px var(--secondary-color);
    background: var(--secondary-color);
  }
  
  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .icon-colour {
    color: var(--background);
  }

@media (max-width: 900px) {
    .about-section{
        flex-direction: column;
        gap: 200px;
    }
    .about-section .col-one{
        width: 100%;
    }

}



/* --------- */
/* services Section  */
/* --------- */



.services-container{
    width: 100%;
    min-height: 100vh;
    padding-top: 150px;
}
.services-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.services-section .col-one{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.services-section .col-one h2{
    color: var(--secondary-color);
    text-align: left;
    font-weight: 900;
    font-size: 3rem;
}
.services-section .col-one h3{
    color: var(--text-primary);
    text-align: left;
    padding: 0;
    margin: 0;
}
.services-section .col-one hr{
    height: 3px;
    width: 400px;
    margin-top: 5px;
    color: var(--secondary-color);

}

.services-section .col-one p{
    text-align: left;
    color: var(--text-primary);
}

.services-section .row-one{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
}
.service-item{
    width: 40%;
    height: 320px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid var(--secondary-color);
    backdrop-filter: blur(3px);
    box-shadow: 0px 10px 10px 0px #171d052c;
}
.service-item img{
    width: 60px;
    margin: 10px 15px;
}
.service-item h3{
    font-size: medium;
    font-weight: 900;
    color: var(--secondary-color);
    text-align: left;
    padding-left: 13px;
    margin: 0;
    margin-top: -25px;
}
.service-item hr{
    color: var(--secondary-color);
    width: 80%;
    margin: 20px 10px;
}
.service-item ul{
    text-align: left;
    color: var(--text-primary);
    margin: 0;
}
.service-item ul li{
    text-align: left;
    color: var(--text-primary);
    font-size: smaller;
}


.services-section-two .row-one{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 40px;
    gap: 20px;
}
.services-section-two .row-one .service-item{
    height: 280px;
}

@media (max-width: 900px) {
    .services-section{
        flex-direction: column;
        align-items: start;
    }
    .services-section .col-one{
        width: 100%;
    }
    .services-section .row-one{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        margin-left: 4px;
        align-items: center;
        padding: 0;
    }
    .service-item{
        width: 100%;
        height: 600px;
        padding: 20px 10px;
    }
    .service-item img{
        width: 50px;
    }
    .service-item h3{
        font-size: medium;
        font-weight: 900;
        color: var(--secondary-color);
        text-align: left;
        padding-left: 13px;
        margin: 0;
        margin-top: -25px;
    }
    
    
    .services-section-two .row-one{
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        margin-left: 0px;
    }

}




/* --------- */
/* services Section  */
/* --------- */




.stats-container{
    width: 100%;
    padding-top: 100px;
    min-height: 60vh
}

.stats-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;


}
.stats-section .col-one-fade{
    width: 50%;
    height: fit-content;
    gap: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.stats-section .col-one{
    width: 100%;
    height: fit-content;
    gap: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.stats-section .col-one .stat-item{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.stats-section .col-one .stat-item p:first-child{
    font-size: 4rem;
    font-weight: bolder;
    margin: 0;
    color: var(--text-primary);

}
.stats-section .col-one .stat-item p:last-child{
    width: fit-content;
    flex: 1 1;
    font-size: .8rem;
    padding: .5rem 1rem;
    border-radius: 50px;
    margin: 0;
    color: var(--background);
    background-color: var(--text-primary);
}

.stats-section .col-two{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.stats-section .col-two h2{
    color: var(--secondary-color);
    text-align: left;
    font-weight: 900;
    font-size: 3rem;
}
.stats-section .col-two h3{
    color: var(--text-primary);
    text-align: left;
    padding: 0;
    margin: 0;
}
.stats-section .col-two hr{
    height: 3px;
    width: 400px;
    margin-top: 5px;
    color: var(--secondary-color);

}

.stats-section .col-two p{
    text-align: left;
    color: var(--text-primary);
}

@media (max-width: 900px) {
    
    .stats-section{
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;


    }
    .stats-section .col-one .stat-item{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 30px 0px;
    }
    .stats-section .col-one-fade{
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
    }
    .stats-section .col-one{
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
    }

    .stats-section .col-one .stat-item p:first-child{
        font-size: 3rem;
        font-weight: bolder;
        margin: 0;
        color: var(--text-primary);
    
    }


    .stats-section .col-two{
        width: 100%;
    }
    .stats-section .col-two hr{
        width: 350px;

    }
}




/* --------- */
/* WhyUs Section  */
/* --------- */





.WhyUs-container{
    width: 100%;
    min-height: 100vh;
    padding-top: 100px;
}
.WhyUs-section{
    width: 100%;

}
.WhyUs-section .col-one{
    width: 50%;

}
.WhyUs-container h2{
    color: var(--secondary-color);
    text-align: left;
    font-weight: 900;
    font-size: 3rem;
    padding-bottom: 50px;

}

.WhyUs-section .col-one .reasons-item{
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.WhyUs-section .col-one .reasons-item .icon-container{
    width: 50px;
    height: 50px;
    background-color: var(--secondary-color);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.WhyUs-section .col-one .reasons-item .icon-container img{
    width: 30px;
    height: 30px;
    filter: saturate(700);
    object-fit: contain;
}
.WhyUs-section .col-one .reasons-item .item-col{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.WhyUs-section .col-one .reasons-item .item-col h3{
    color: var(--text-primary);
    text-align: left;
    padding: 0;
    margin: 0;

}
.WhyUs-section .col-one .reasons-item .item-col p{
    text-align: left;
    color: var(--text-primary);
}

@media (max-width: 900px) {
    .WhyUs-section{
        width: 100%;
        display: flex;
        flex-direction: column;
    
    }
    .WhyUs-section .col-one{
        width: 100%;
    
    }
    .WhyUs-container h2{
        font-size: 2rem;
    
    }
    .WhyUs-section .col-one .reasons-item .item-col h3{
        color: var(--text-primary);
        text-align: left;
        font-size: 1.3rem;
        font-weight: bold;
        padding: 0;
        margin: 0;
    
    }
    .WhyUs-section .col-one .reasons-item .item-col p{
        text-align: left;
        color: var(--text-primary);
    }
    .WhyUs-section .col-one img{
        width: 100%;
        margin: 50px 0px;
    }

}

.vission-mission{
    grid-area: 20px;
    margin: 100px 0px;
    gap: 20px;
}


.feature-card {
    flex: 1;
    backdrop-filter: blur(60px);
    border: 1px solid #35405300;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon {
    background: var(--text-secondary);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    color: var(--secondary-color);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }
  
  .feature-card h3 {
    font-size: 18px;
    margin-bottom: 12px;
    color: var(--secondary-color);
    text-align: start;
  }
  
  .feature-card p {
    color: var(--text-primary);
    font-size: 13px;
    line-height: 1.5;
    text-align: start;
  }



  .map{
    width: 100%;
    min-height: 80vh;
    margin: 150px 0px;
  }
  .iframe{
    border-radius: 25px;
    overflow: hidden;
    min-height: 80vh;
  }

  @media (max-width: 900px) {
    .map{
        min-height: 45vh;}
    .iframe{
        min-height: 40vh;
        margin: 50px 0px;
      }
  }
