.footer-container{
    width: 100%;
    min-height: 50vh;
    background-color: var(--secondary-color);
}
.footer-container .footer-section{
    width: 100%;
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}
.footer-container .col-one{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}
.footer-container .col-two{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: start;
    
    gap: 0px;
}
.footer-container img {
    filter: grayscale(100%) brightness(1000%);
}
.footer-section .col-one,.col-two p{
    text-align: left;
    color: var(--background);
    font-size: small;
    
}
.footer-section .col-two h2{
    color: var(--background);
    text-align: left;
    font-weight: 900;
    font-size: 3rem;
}
.footer-section .col-two h3{
    color: var(--background);
    text-align: left;
    padding: 0;
    margin: 0;
}
.footer-section .col-two hr{
    height: 3px;
    width: 310px;
    margin-top: 5px;
    color: var(--background);

}
.footer-section input{
    width: 100%;
    padding: 15px 25px;
    margin: 10px 0px;
    color: var(--secondary-color);
    border-radius: 20px;
    border: 0px;
}
.copyright-section{
    background-color: aqua;
    width: 100%;
    padding: 30px;
}
.footer {
    background-color: #00000000;
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
    justify-content: space-between;
  }
  .footer-copywright {
    text-align: center !important;
  }
  
  .footer-body {
    z-index: 1;
    text-align: center !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center !important;
    }
  
    .footer-body {
      text-align: center !important;
    }
    .footer-container .footer-section{
        width: 100%;
        display:  flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        gap: 40px;
    }
    .footer-container .col-one{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .footer-container .col-two{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        
        gap: 0px;
    }
  }
  
  .footer h3 {
    font-size: 1em;
    color: var(--background) !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .footer-icons {
    margin-top: 0.5em ;
    margin-bottom: 0.5em ;
    padding: 0 ;
    color: var(--background) ;
  }
  
  .blockquote-footer {
    color: var(--background) ;
  }

  
  
  