/* Base Modal Structure */
.dialogue-section {
  position: absolute;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(7px);
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900000;
}

.dialogue-container {
  height: auto;
  max-height: 80vh;
  width: 90%;
  max-width: 500px;
  border: 1px solid var(--secondary-color);
  border-radius: 40px;
  background-color: white;
  padding: 15px 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

/* Modal Header */
.dialogue-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #f0e0f6;
}

.dialogue-header h3 {
  width: fit-content;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.close-btn {
  background: none;
  border: none;
  font-size: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  width: 40px;
  height: 40px;
}

/* Modal Content Area */
.dialogue-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding-right: 5px;
  flex: 1;
  max-height: calc(80vh - 150px);
}

/* Custom Scrollbar */
.dialogue-content::-webkit-scrollbar {
  width: 6px;
}

.dialogue-content::-webkit-scrollbar-track {
  background: #f1f1f100;
  border-radius: 3px;
}

.dialogue-content::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 3px;
}

.dialogue-content::-webkit-scrollbar-thumb:hover {
  background: #55555509;
}

/* Body Behavior */
body.dialog-open {
  overflow: hidden;
}

/* Selection Container */
.select-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.select-container span {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px solid #eee;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-container span:hover {
  border-color: var(--secondary-color);
  background-color: #f9f9f9;
}

.select-container span.selected {
  border-color: var(--secondary-color);
  background-color: rgba(62, 167, 1, 0.1);
}

.select-container svg {
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.select-container p {
  margin: 0;
  font-weight: 500;
}

/* Button Styles */
.next-btn, .confirm-btn {
  background-color: var(--secondary-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 25px;
  border-radius: 30px;
  font-weight: 500;
  align-self: flex-end;
}

.next-btn:disabled, .confirm-btn:disabled {
  background-color: #66666691;
}

.back-btn {
  background-color: #666;
  border: none;
  padding: 10px 25px;
  border-radius: 30px;
  font-weight: 500;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Location Input Styles */
.location-inputs-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.location-input-group {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
}

.location-input-group input, 
.location-input-group select {
  width: 100%;
  padding: 12px 40px 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.location-input-group input:focus {
  border-color: var(--secondary-color);
  outline: none;
}

.location-picker-btn {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: var(--secondary-color);
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Location Suggestions Dropdown */
.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000000;
  margin-top: 2px;
}

.suggestion-item {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover {
  background-color: #f9f9f9;
}

.suggestion-item.loading {
  text-align: center;
  color: var(--text-primary);
  font-style: italic;
}

.suggestion-item.no-results {
  text-align: center;
  color: #666;
  font-style: italic;
}

/* Map Modal Styles */
.map-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.map-container {
  width: 90%;
  max-width: 800px;
  height: 80%;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.map-header h4 {
  margin: 0;
  font-weight: 600;
}

.close-map-btn {
  font-size: 24px;
  cursor: pointer;
}

.map-search {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.map-search input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.map-search .suggestions-dropdown {
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 100000;
}

.map-search-button {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 6px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.map-view {
  flex: 1;
  background-color: #f0f0f0;
}

.map-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
}

.map-footer p {
  margin: 5px 0;
  font-size: 14px;
}

.confirm-location-btn {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  float: right;
}

.custom-marker-icon {
  background: none;
  border: none;
}

/* Quote Details Styles */
.quote-details-container {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  gap: 15px;
  border: 1px solid #eee;
}

.quote-icon {
  font-size: 40px;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 10px;
}

.quote-type {
  text-align: center;
}

.quote-type h4 {
  font-weight: 600;
  margin: 0;
  color: #333;
}

.quote-route {
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
  padding: 10px 0;
}

.quote-route p, 
.quote-distance p {
  margin: 5px 0;
  font-size: 15px;
}

.quote-distance {
  padding-bottom: 10px;
}

.quote-price {
  text-align: center;
  padding: 5px;
  background-color: rgba(62, 167, 1, 0.1);
  border-radius: 10px;
}

.quote-price h3 {
  margin: 0;
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 28px;
}

.price-note {
  margin: 5px 0 0;
  font-size: 12px;
  color: #777;
}

/* Contact Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 500;
  font-size: 14px;
}

.form-group input, 
.form-group select {
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.form-group input:focus, 
.form-group select:focus {
  border-color: var(--secondary-color);
  outline: none;
}

/* House Type Selection Styles */
.house-type-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.house-type-option {
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.house-type-option:hover {
  border-color: var(--secondary-color);
  background-color: #f9f9f9;
}

.house-type-option.selected {
  border-color: var(--secondary-color);
  background-color: rgba(62, 167, 1, 0.1);
}

/* Thank You Message Styles */
.thank-you-message {
  text-align: center;
  padding: 20px;
}

.thank-you-message h3 {
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.thank-you-message p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.thank-you-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}